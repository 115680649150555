@import '../node_modules/@angular/material/theming';
@include mat-core();

// Define your custom primary color
$custom-primary: mat-palette($mat-cyan, 800);
$custom-accent: mat-palette($mat-cyan, 800);

// Create a custom theme with the new primary color
$custom-theme: mat-light-theme(
  $custom-primary,
  $custom-accent
);
/* * {
    font-family: sans-serif;
} */
// Include the custom theme
@include angular-material-theme($custom-theme);

:root {
	--poppins: 'Poppins', sans-serif;
	--lato: 'Lato', sans-serif;

	--light: #F9F9F9;
	--primary: #016F86;
    --primary1: #2596be;
    --primary2: #00B19e;
	--light-blue: #CFE8FF;
	--grey: #eee;
	--dark-grey: #AAAAAA;
	--dark: #342E37;
	--red: #DB504A;
	--yellow: #FFCE26;
	--light-yellow: #FFF2C6;
	--orange: #FD7238;
	--light-orange: #FFE0D3;
}
body {
    font-size: 16px !important;
    font-family: serif !important;
    margin: 0;
    height: 100vh;
}
input:focus {
  border: 1px solid gray !important; 
  outline: none !important;
  box-shadow: none !important;
}

.mat-mdc-dialog-container{
    max-width: none !important;
}
.mat-mdc-dialog-surface{//avoid scroll of dialog
    overflow: hidden !important;
}

.mdc-list-item__primary-text{
    color: black !important;
}
.select-container{
    position: relative;
}
.select-container button{
    padding: 0.2em 12px 0.2em 0.3em;
    //color: black !important;
    background-color: white;
    border-radius: 5px !important;
    border: 1px solid gray;
  }
  .select-container button:hover{
    background-color: white;
  }
  
  .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    //text-decoration: none !important;
    color: #ffffff !important;
    background-color: var(--primary) !important;
  }
  .dropdown-item.active{
    color: black;
    background-color: white;
  }
  .bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .bootstrap-select .dropdown-toggle{
      z-index: 0;
    } 
  
  .progress_bar{
    background-color: white;
    z-index: 1000;
  }
/* MULTISELECT */
.cdk-overlay-connected-position-bounding-box > .cdk-overlay-pane {
    width: auto !important; //force the dropdown menu to take the content's width
}
.mdc-line-ripple::before, .mdc-line-ripple::after {
    border-bottom-style: none !important; //remove bottom border
}

.mat-mdc-text-field-wrapper{
    border: 1px solid #CFD8DC !important; //Create a line border
    border-radius: 5px !important;//radius border
}
.mat-mdc-form-field-infix{
    padding: 0.2em 0.3em 0.2em 0.3em !important;//Force and ajuste padding inside the selection
}

.mat-mdc-text-field-wrapper {
    height: 35px !important;//Force height
}

.mdc-text-field{
    padding: 0 0 !important; //Force and remove padding left and right
}

.mat-mdc-form-field-subscript-wrapper{
    display: none;//Remove empty space in bottom of the select 
}

.mat-mdc-option-pseudo-checkbox{
    display: none !important;//hide multiselect checkbox
}

.mat-mdc-option{
    min-height: 35px !important; //Set height of the options 
} 

.mat-mdc-option .mat-icon, .mat-mdc-option .mat-pseudo-checkbox-full{
    margin-right: 0px !important;
}

.mdc-list-item__primary-text{
    width: 100%; //dropdown option content width
    .mat-option-checkbox{
        float: right;
    }
}

mat-option{
    border-top: 1px solid white; //draw a separator row for each option
}

.custom-mat-select .mat-selected-option {
    color: inherit; /* Use 'inherit' to inherit the color from the parent */
}

.mat-mdc-select-arrow svg{
    fill: black !important;
}

.mdc-menu-surface.mat-mdc-select-panel{
    background-color: white;
}

.mat-mdc-select{
    padding: 0.3em 0.3em 0.3em 0.3em;
    width: -webkit-fill-available !important;
    background-color: white;
    border-radius: 5px !important;
    border: 1px solid gray;
}
/* Mat Date button picker */
.mat-mdc-icon-button.mat-mdc-button-base {
    display: flex;
    align-items: center;
    width: 24px;
    padding: 0px;
    height: auto;
}
.mat-date-range-input-container {
    height: 100%;
    display: flex;
    align-items: center;
}
/* Expand Collaps */
.mat-expansion-panel-body {
  padding: 0 2px 5px !important;
}
.mat-expansion-panel-header{
  height: min-content !important;
  flex-direction: column-reverse !important;
}
/* Input */
.col-input {
    padding: 0;
    display: flex;
    width: 100%;
    padding: 0.02rem 0rem 0.02rem 0.05rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.9;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
  }
  
  .col-input::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  
  .col-input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  }
  
  .col-input::placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .col-input:disabled, .col-input[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }

  /* INPUT 2 */
  .col-input-filter {
    padding: 0;
    display: flex;
    width: -webkit-fill-available;
    padding: 0.02rem 0rem 0.02rem 0.05rem;
    margin: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.9;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
  }
  
  .col-input-filter::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  
  .col-input-filter:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  }
/* BUTTON */
.button-validate {
	border-radius: 20px;
	border: 1px solid var(--primary);
	background-color: var(--primary);
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
    cursor: pointer;
}
.button-validate-small {
    float: right;
	border-radius: 5px;
	border: 1px solid var(--primary);
	background-color: var(--primary);
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
    padding: 5px;
	letter-spacing: 1px;
	transition: transform 80ms ease-in;
    cursor: pointer ;
}
.button-secondary-small{
    float: right;
	border-radius: 5px;
	border: 1px solid gray;
    color: black;
	background-color: whitesmoke;
	font-size: 12px;
	font-weight: bold;
    padding: 5px;
	letter-spacing: 1px;
	transition: transform 80ms ease-in;
    cursor: pointer ;
}

  /* hover title */
  tbody td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    position: relative; 
  }

    /* Toggle */
    .switch-toggle {
      border: 1px solid #dfdfdf;
      display: inline-block;
      box-sizing: content-box;
      overflow: visible;
      margin-right: 10px;
      width: 42px;
      height: 20px;
      background-color: light-grey;
      border-radius: 20px;
      cursor: pointer;
      box-shadow: rgb(223, 223, 223) 0 0 0 0 inset;
      transition: 0.3s ease-out all;
      -webkit-transition: 0.3s ease-out all;
      small {
        border-radius: 100%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        transition: 0.3s ease-out all;
        -webkit-transition: 0.3s ease-out all;
        background-color: white;
      }
      
    }
    .switch-checked {
      background-color: var(--primary);
    }
    .switch-toggle.switch-checked small {
      left: 25px;
    }


  router-outlet + * { 
      display: block;
      height: calc(100% - 54px);
  }